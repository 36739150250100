/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoleDto } from './roleDto';
import { CardDto } from './cardDto';
import { ShopDto } from './shopDto';
import { SiteDto } from './siteDto';


export interface CustomerDto { 
    code?: string | null;
    id?: number;
    matricule?: string | null;
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    role?: RoleDto;
    card?: CardDto;
    lastLogin?: string | null;
    azureAdUpn?: string | null;
    azureAdUsername?: string | null;
    otp?: string | null;
    companyId?: number;
    sites?: Array<SiteDto> | null;
    shops?: Array<ShopDto> | null;
}

