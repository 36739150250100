/**
 * EliorGroup.Joyfood.Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateCustomerCommand { 
    username?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    readonly azureAdUpn?: string | null;
    readonly azureAdUsername?: string | null;
    readonly otp?: string | null;
    physicalCardId?: string | null;
    roleId: number;
    sites: Array<number>;
}

